.add-card-form {
  text-align: center;
}

.add-card-form-submit {
  width: auto;
  display: inline-block;
}

.add-card-form-loader {
  position: absolute !important;
  top: 50%;
  left: 50%;
  margin-top: -3px;
  margin-left: -12px;
}

.add-card-form-terms {
  color: #333;
  width: 75%;
  margin: 20px auto;
}

.add-card-form-terms a {
  color: deepskyblue;
  cursor: pointer;
}

.change-card {
  margin-top: -23px;
  margin-bottom: 32px;
}

.change-card a {
  color: deepskyblue;
  cursor: pointer;
}

.change-card-loader {
  position: absolute !important;
  top: 50%;
  left: 50%;
  margin-top: -3px;
  margin-left: -12px;
}
