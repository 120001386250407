.language-selector {
  position: absolute;
  right: 0;
}

.language-selector__menu {
  margin-left: -0.5em;
  right: 0;
  padding-left: 0;
  color: #fff;
}

/* Selected item bottom border removed */
.language-selector__menu > div > div:nth-child(4) {
  border: 0 !important;
}

.language-selector__menu > div > svg {
  fill: rgba(0, 0, 0, 0.870588) !important;
}
