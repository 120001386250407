/* montserrat-300 - latin-ext_cyrillic-ext_vietnamese_latin_cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  src: local('Montserrat Light'), local('Montserrat-Light'),
       url('./montserrat-v12-latin-ext_cyrillic-ext_vietnamese_latin_cyrillic-300.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('./montserrat-v12-latin-ext_cyrillic-ext_vietnamese_latin_cyrillic-300.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* montserrat-300italic - latin-ext_cyrillic-ext_vietnamese_latin_cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 300;
  src: local('Montserrat Light Italic'), local('Montserrat-LightItalic'),
       url('./montserrat-v12-latin-ext_cyrillic-ext_vietnamese_latin_cyrillic-300italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('./montserrat-v12-latin-ext_cyrillic-ext_vietnamese_latin_cyrillic-300italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* montserrat-regular - latin-ext_cyrillic-ext_vietnamese_latin_cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: local('Montserrat Regular'), local('Montserrat-Regular'),
       url('./montserrat-v12-latin-ext_cyrillic-ext_vietnamese_latin_cyrillic-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('./montserrat-v12-latin-ext_cyrillic-ext_vietnamese_latin_cyrillic-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* montserrat-italic - latin-ext_cyrillic-ext_vietnamese_latin_cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 400;
  src: local('Montserrat Italic'), local('Montserrat-Italic'),
       url('./montserrat-v12-latin-ext_cyrillic-ext_vietnamese_latin_cyrillic-italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('./montserrat-v12-latin-ext_cyrillic-ext_vietnamese_latin_cyrillic-italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* montserrat-500italic - latin-ext_cyrillic-ext_vietnamese_latin_cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 500;
  src: local('Montserrat Medium Italic'), local('Montserrat-MediumItalic'),
       url('./montserrat-v12-latin-ext_cyrillic-ext_vietnamese_latin_cyrillic-500italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('./montserrat-v12-latin-ext_cyrillic-ext_vietnamese_latin_cyrillic-500italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* montserrat-500 - latin-ext_cyrillic-ext_vietnamese_latin_cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  src: local('Montserrat Medium'), local('Montserrat-Medium'),
       url('./montserrat-v12-latin-ext_cyrillic-ext_vietnamese_latin_cyrillic-500.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('./montserrat-v12-latin-ext_cyrillic-ext_vietnamese_latin_cyrillic-500.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* montserrat-600italic - latin-ext_cyrillic-ext_vietnamese_latin_cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 600;
  src: local('Montserrat SemiBold Italic'), local('Montserrat-SemiBoldItalic'),
       url('./montserrat-v12-latin-ext_cyrillic-ext_vietnamese_latin_cyrillic-600italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('./montserrat-v12-latin-ext_cyrillic-ext_vietnamese_latin_cyrillic-600italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* montserrat-600 - latin-ext_cyrillic-ext_vietnamese_latin_cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  src: local('Montserrat SemiBold'), local('Montserrat-SemiBold'),
       url('./montserrat-v12-latin-ext_cyrillic-ext_vietnamese_latin_cyrillic-600.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('./montserrat-v12-latin-ext_cyrillic-ext_vietnamese_latin_cyrillic-600.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

