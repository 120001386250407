@import 'fonts/montserrat.css';

.hidden {
  display: none;
}

.bold {
  font-weight: 600;
}

.right-aligned {
  text-align: right;
}

.right-aligned * {
  text-align: center;
}

.padded {
  padding: 16px 0;
}

.flex {
  display: flex;
  justify-content: space-around;
}

.flex > * {
  text-align: center;
}

.wrapper {
  position: relative;
  padding: 16px;
}

.wrapper--center {
  position: relative;
  text-align: center;
}

.wrapper__inner {
  padding: 16px 0 0;
}

.wrapper--flex {
  display: flex;
  justify-content: space-between;
  text-align: center;
}

.title {
  font-size: 0.875rem;
  font-weight: 400;
}

.box {
  padding: 8px 16px 24px;
  border-radius: 0 !important;
}

@media (min-width: 600px) {

  .box {
    padding: 16px 32px 24px;
  }
}

.field {
  position: relative;
}

label {
  color: #aaa;
  font-size: 14px;
  line-height: 14px;
  display: block;
  position: relative;
    bottom: -24px;
  z-index: 0;
  transition: all .2s ease-in-out;
}

label.active {
  font-size: 12px;
  color: #003748;
  transform: translateY(-25px);
}

input[type="text"] {
  outline: 0;
  border: 0;
  border-bottom: 1px solid #aaa;
  background: none;
  z-index: 2;
  position: relative;
  height: 30px;
  width: 100%;
}

input[type="text"]:focus,
input[type="text"]:active,
input[type="text"]:hover {
  outline: 0;
}

input[type="text"]:focus {
  border-bottom: 1px solid #003748;
}

::-webkit-input-placeholder {
   font-weight: 300;
}

:-moz-placeholder { /* Firefox 18- */
   font-weight: 300;
}

::-moz-placeholder {  /* Firefox 19+ */
   font-weight: 300;
}

:-ms-input-placeholder {
   font-weight: 300;
}

.button {
  background: #003748;
  color: #fff;
  border: 0;
  outline: 0;
  border-radius: 2px;
  padding: 8px 16px;
  font-size: 0.875rem;
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
}

.button--gray {
  background: #eee;
  color: currentColor;
}

.button--centered {
  width: auto;
  display: block;
  margin: auto;
}

.button--addcard {
  margin-top: 16px;
}

.button--raised {
  box-shadow: 0 1px 3px rgba(0,0,0,0.3);
}

.choosables {
  margin: 8px auto;
}

.choosable {
  margin: 4px;
  background: none;
  color: currentColor;
  font-size: 0.75rem;
}

.choosable--active {
  background: #eee;
}

.charging-info {
  margin: 1.5rem auto;
}

.charging-info p,
.charging-info a,
.charging-info span {
  font-size: 0.75rem;
}

.chargingTitle {
  margin: 0;
}

.chargingValue {
  color: #5fb100;
  margin: 0;
  margin-bottom: 8px;
  font-size: 2rem;
}

.link {
  font-size: 0.875rem;
  text-decoration: none;
  padding-left: 8px;
  padding-right: 8px;
  color: #00b1ff;
}

.link:hover,
.link:active {
  color: #78d4fc;
}

.modal {
  z-index: -1;
  opacity: 0;
  position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  transition: all .2s ease-in-out;
}

.modal--opening {
  opacity: 0;
  background: rgba(0,0,0,0.3);
}

.modal--open {
  opacity: 1;
  z-index: 1;
  background: rgba(0,0,0,0.3);
}

.modal-content {
  position: absolute;
    top: 50%;
    left: 50%;
  transform: translateY(-50%) translateX(-50%);
  z-index: 2;
  width: 100%;
  max-width: 400px;
}

/* apply a natural box layout model to all elements, but allowing components to change */

html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  font-weight: 400;
  font-size: 16px;
}

body {
  font-family: 'Montserrat', 'Helvetica Neue', sans-serif;
  background: #eee;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 400;
}

p, input, span, li, a, div {
  font-size: inherit;
}

.cardImage {
  height: 16px;
  display: inline-block;
  margin-right: 16px;
}

.cardImageTwint {
  height: 34px;
  display: inline-block;
}

.cardNumber {
  color: #ccc;
}

.round {
  display: inline-block;
  border: 2px solid white;
  padding: 5px 5px 3px;
  border-radius: 50%;
}

.logo {
  font-size: 30pt;
}
