.blinking-battery-icon {
  animation-name: blink;
  animation-duration: 2s;
  animation-timing-function: ease-in-out;
  animation-delay: 0;
  animation-iteration-count: infinite;
  animation-play-state: running;
}

@-webkit-keyframes blink {
  0% {
    opacity: 1;
  }

  50% {
    opacity: .1;
  }

  100% {
    opacity: 1;
  }
}

@keyframes blink {
  0% {
    opacity: 1;
  }

  50% {
    opacity: .1;
  }

  100% {
    opacity: 1;
  }
}
