.charging-price-table table {
  width: 100%;
  font-size: 0.875rem;
  background: #eee;
  border-collapse: collapse;
}

.charging-price-table table thead th {
  color: #fff;
}

.charging-price-table table td,
.charging-price-table table th {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  text-align: center;
}

.charging-price-table th:first-child {
  text-align: left;
  padding-left: 10px;
}

.charging-price-table th:nth-child(2),
.charging-price-table td:nth-child(2) {
  text-align: right;
}

.charging-price-table th:nth-child(3),
.charging-price-table td:nth-child(3) {
    width: 10%;
    text-align: center;
}

.charging-price-table th:nth-child(4),
.charging-price-table td:nth-child(4) {
  text-align: center;
}

.charging-price-table th:nth-child(5),
.charging-price-table td:nth-child(5) {
    width: 10%;
    text-align: center;
}

.charging-price-table th:nth-child(6),
.charging-price-table td:nth-child(6) {
  text-align: left;
}

.charging-price-table td[colspan] {
  font-size: 0.75rem;
  padding-top: 0.5rem;
}

.charging-price-table td[colspan] span {
  font-weight: 600;
  font-size: 0.75rem;
}

p.charging-price-additional-info {
  text-align: center;
  font-size: 14px;
}
