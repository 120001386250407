.content {
  max-width: 400px;
  margin: 0 auto;
  width: 100%;
  padding: 8px;
}

main {
  width: 100%;
  height: 100%;
  max-width: 400px;
  margin: 8px auto;
  position: relative;
}

main > div {
  border-radius: 0 !important;
}

@media (min-width: 768px) {
  .content {
    display: flex;
    width: 100%;
    max-width: 768px;
  }

  .content .banner {
    width: 40%;
    max-width: none;
  }

  .content main {
    width: 60%;
    margin-top: 0;
    margin-bottom: 0;
    max-width: none;
  }
}
