.notification-area {
  max-width: 400px;
  margin: 0 auto;
  width: 100%;
  padding: 0px 8px;
}

@media (min-width: 768px) {
  .notification-area {
    width: 100%;
    max-width: 768px;
  }
}
