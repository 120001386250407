.banner {
  margin: 0 auto;
  max-width: 400px;
  padding: 0;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.117647) 0px 1px 6px, rgba(0, 0, 0, 0.117647) 0px 1px 4px;
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  background: rgba(255, 255, 255, 0.8);
  color: #000;
  padding: 8px 32px;
  height: auto;  
  position: relative;
}

.banner__wrapper {
  display: flex;
  height: 100%;
  flex-direction: column;
}

.banner-top {
  flex-grow: 1;
}

.banner-bottom {
  margin-bottom: 16px;
}

@media (min-width: 768px) {
  .banner-bottom {
    position: absolute;
    bottom: 8px;
  }

  .banner__wrapper {
    font-size: 16px !important;
  }

  .banner__wrapper h2 {
    font-size: 20px !important;
  }
}
